<template>
  <div class="editor">
    <!--   HEADER   -->
    <CreateHeader
      :loading="false"
      :descriptions="descriptions"
      title="Editor"
      submit-title="update"
    />
    <!-- CONTENT -->
    <div class="form-content">
      <div id="gjs"></div>
    </div>
  </div>
</template>

<script>
import CreateHeader from '@/shared/components/CreateHeader';
import {webAssets} from '@/config/descriptions/webAssets';
import Grapes from '@ziqni-tech/web-builder';
import homesinoTemplate from '@/mock/homesinoTemplate';

const grapes = new Grapes('homesino', homesinoTemplate);

export default {
  name: "FileEditor",
  data() {
    return {
      descriptions: {
        ...webAssets.file.fileEditor
      },
    }
  },
  components: {
    CreateHeader
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      grapes.init();
    },
    handleUpdate() {
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.editor {
  height: 100%;
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
    border-top: 1px solid var(--zq-content-border-color);
  }
}
</style>
