export default {
    logoSrc: 'https://avatars.githubusercontent.com/u/36859861?s=280&v=4',
    avatarSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQuLB8WMVX3qR3A70Ho96IrQ32FrhnYLhfDg&usqp=CAU',
    prod: [
        {
            "title": "one",
            "content": "some",
            "src": 'https://cdn4.iconfinder.com/data/icons/pretty-office-part-6-shadow-style/256/Basketball.png'
        },
        {
            "title": "two",
            "content": "asdasdsadsadsad asdd",
            "src": 'https://icons.iconarchive.com/icons/aha-soft/business/256/casino-icon.png'
        },
        {
            "title": "two",
            "content": "asdasdsadsadsad asdd",
            "src": 'https://icons.iconarchive.com/icons/aha-soft/business/256/casino-icon.png'
        },
        {
            "title": "two",
            "content": "asdasdsadsadsad asdd",
            "src": 'https://icons.iconarchive.com/icons/aha-soft/business/256/casino-icon.png'
        },
        {
            "title": "one",
            "content": "some",
            "src": 'https://cdn4.iconfinder.com/data/icons/pretty-office-part-6-shadow-style/256/Basketball.png'
        },
        {
            "title": "one",
            "content": "some",
            "src": 'https://cdn4.iconfinder.com/data/icons/pretty-office-part-6-shadow-style/256/Basketball.png'
        },
        {
            "title": "one",
            "content": "some",
            "src": 'https://cdn4.iconfinder.com/data/icons/pretty-office-part-6-shadow-style/256/Basketball.png'
        },
        {
            "title": "one",
            "content": "some",
            "src": 'https://cdn4.iconfinder.com/data/icons/pretty-office-part-6-shadow-style/256/Basketball.png'
        },
        {
            "title": "one",
            "content": "some",
            "src": 'https://cdn4.iconfinder.com/data/icons/pretty-office-part-6-shadow-style/256/Basketball.png'
        },
        {
            "title": "one",
            "content": "some",
            "src": 'https://cdn4.iconfinder.com/data/icons/pretty-office-part-6-shadow-style/256/Basketball.png'
        },
        {
            "title": "one",
            "content": "some",
            "src": 'https://cdn4.iconfinder.com/data/icons/pretty-office-part-6-shadow-style/256/Basketball.png'
        },
        {
            "title": "two",
            "content": "asdasdsadsadsad asdd",
            "src": 'https://icons.iconarchive.com/icons/aha-soft/business/256/casino-icon.png'
        },
        {
            "title": "two",
            "content": "asdasdsadsadsad asdd",
            "src": 'https://icons.iconarchive.com/icons/aha-soft/business/256/casino-icon.png'
        },
        {
            "title": "two",
            "content": "asdasdsadsadsad asdd",
            "src": 'https://icons.iconarchive.com/icons/aha-soft/business/256/casino-icon.png'
        },
        {
            "title": "two",
            "content": "asdasdsadsadsad asdd",
            "src": 'https://icons.iconarchive.com/icons/aha-soft/business/256/casino-icon.png'
        },
        {
            "title": "two",
            "content": "asdasdsadsadsad asdd",
            "src": 'https://icons.iconarchive.com/icons/aha-soft/business/256/casino-icon.png'
        },
    ],
    achievements: [
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '1',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '2',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '3',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '4',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '5',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '6',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '7',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '8',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '9',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://icons-for-free.com/iconfiles/png/512/cup+reward+star+winner+icon-1320183068046762272.png',
            name: '10',
            label: 'Achievement',
            member: 'John',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '11',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '12',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '13',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '14',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '15',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '16',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '17',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '18',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '19',
            label: 'Achievement',
            member: 'Doe',
        },
        {
            icon: 'https://img.favpng.com/17/20/23/trophy-medal-icon-png-favpng-6jtb0RjY9qH07Uh5jqgTmwh86.jpg',
            name: '20',
            label: 'Achievement',
            member: 'Doe',
        },
    ],
    UComps: [
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Wager race',
            timer: '-00d 00h 35min',
            reward: 'none',
            rank: '141 st',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Points chase -target 1000 p...',
            timer: '-00d 02h 12min',
            reward: 'none',
            rank: 'none',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Single Highest Coin win',
            timer: '-00d 00h 15min',
            reward: 'Free spins',
            rank: '1 st',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Points chase -target 1000 p...',
            timer: '-00d 02h 12min',
            reward: 'none',
            rank: 'none',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Single Highest Coin win',
            timer: '-00d 00h 15min',
            reward: 'Free spins',
            rank: '2 nd',
        },
    ],
    AvAchievement: [
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Wager race',
            timer: '-00d 00h 35min',
            reward: 'none',
            rank: '141 st',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Points chase -target 1000 p...',
            timer: '-00d 02h 12min',
            reward: 'none',
            rank: 'none',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Single Highest Coin win',
            timer: '-00d 00h 15min',
            reward: 'Free spins',
            rank: '1 st',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Points chase -target 1000 p...',
            timer: '-00d 02h 12min',
            reward: 'none',
            rank: 'none',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Single Highest Coin win',
            timer: '-00d 00h 15min',
            reward: 'Free spins',
            rank: '2 nd',
        },
    ],
    RComps: [
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Wager race',
            timer: '-00d 00h 35min',
            reward: 'none',
            rank: '141 st',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Points chase -target 1000 p...',
            timer: '-00d 02h 12min',
            reward: 'none',
            rank: 'none',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Single Highest Coin win',
            timer: '-00d 00h 15min',
            reward: 'Free spins',
            rank: '1 st',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Points chase -target 1000 p...',
            timer: '-00d 02h 12min',
            reward: 'none',
            rank: 'none',
        },
        {
            icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7GlcAeh77bR29ViliFsnRN3qtfdwekxAsSQ&usqp=CAU',
            label: 'Single Highest Coin win',
            timer: '-00d 00h 15min',
            reward: 'Free spins',
            rank: '2 nd',
        },
    ],
};